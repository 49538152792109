import { useContext, createContext } from 'react';
import { useFeatureFlag } from '@moda/portal-stanchions';

const PLPScoringContext = createContext({ featureFlag: 'plp-scoring' });

export const PLPScoringProvider = PLPScoringContext.Provider;

export const usePLPScoring = () => {
  const { featureFlag } = useContext(PLPScoringContext);
  const rule = useFeatureFlag(featureFlag, [
    { default: 'default' },
    { getFromTreatment: treatment => (typeof treatment === 'string' ? treatment : 'default') }
  ]);

  return rule;
};
