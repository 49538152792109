import React from 'react';
import { useLocation } from 'react-router-dom';
import { omit, tryCatch } from 'ramda';
import { Helmet } from 'react-helmet-async';
import { formatPageTitle } from '@moda/portal-stanchions';
import { color as Color } from '@moda/tokens';
import { CONFIG } from '../../config';
import { useUrlParams, UrlParams } from '../../hooks/useUrlParams';
import { FILTER_KEYS } from '../../hooks/usePLPParams';

type Props = {
  title: string;
  description?: string;
  image?: string;
  paginated?: boolean;
  totalPages?: number;
  canonicalPath?: string;
};

export const PageMeta: React.FC<Props> = ({
  title,
  description,
  image,
  paginated,
  totalPages = 0,
  canonicalPath
}) => {
  const location = useLocation();
  const { getHrefFor, pathnameParams, params } = useUrlParams();

  const tryGetHrefFor = (params: UrlParams, fallback: string | null) =>
    tryCatch(
      () => getHrefFor(params),
      () => fallback
    )();

  const defaultCanonicalPath = tryGetHrefFor(omit(FILTER_KEYS, pathnameParams), location.pathname);
  const canonicalUrl = `https://www.modaoperandi.com${canonicalPath || defaultCanonicalPath}`;

  const page = isNaN(Number(params.page)) ? 1 : Number(params.page);
  const prevPagePath =
    paginated && page > 1 ? tryGetHrefFor({ ...params, page: String(page - 1) }, null) : null;
  const nextPagePath =
    paginated && page < totalPages
      ? tryGetHrefFor({ ...params, page: String(page + 1) }, null)
      : null;

  return (
    <Helmet>
      <title>{formatPageTitle(title)}</title>
      {['dev', 'stage'].includes(CONFIG.ENVIRONMENT) && (
        <meta name="robots" content="noindex,nofollow" />
      )}
      {description && <meta name="description" content={description} />}

      <link rel="canonical" href={canonicalUrl} />
      {prevPagePath && <link rel="prev" href={prevPagePath} />}
      {nextPagePath && <link rel="next" href={nextPagePath} />}

      <meta name="og:site_name" content="Moda Operandi" />
      <meta name="og:url" content={canonicalUrl} />
      <meta name="og:title" content={formatPageTitle(title)} />
      {description && <meta name="og:description" content={description} />}
      {image && <meta name="og:image" content={image} />}
      <meta name="fb:app_id" content={CONFIG.FACEBOOK_APP_ID} />
      <meta name="google-site-verification" content="Wws-Fq-agruoQjHyvs_kzYIYVteoRd1GpPLaOOH9zj0" />
      <link href="/dist/public/favicon.png" rel="shortcut icon" />
      <link rel="apple-touch-icon" href="/dist/public/favicon.png" />
      <meta name="theme-color" content={Color('cream')} />
      <meta
        name="apple-itunes-app"
        content={`app-id=781702094, app-argument=${location.pathname}`}
      />
    </Helmet>
  );
};
