export interface SortOption {
  name: string;
  value: string;
}

export const RELEVANCE: SortOption = { name: 'Relevance', value: 'relevance' };

export const FEATURED_SALE: SortOption = { name: 'Featured', value: 'inventory' };

export const FEATURED: SortOption = { name: 'Featured', value: 'default' };

export const HIGH_TO_LOW: SortOption = { name: 'Price High to Low', value: 'high' };

export const LOW_TO_HIGH: SortOption = { name: 'Price Low to High', value: 'low' };

export const RECENCY_NEW: SortOption = {
  name: "What's new",
  value: 'recency_new'
};

export const RECENCY: SortOption = {
  name: "What's new",
  value: 'recency'
};

export const RECENTLY_ADDED: SortOption = {
  name: 'Recently Added',
  value: 'recently_added'
};

export const DEFAULT_SORT_OPTIONS = [FEATURED, RECENCY, HIGH_TO_LOW, LOW_TO_HIGH];

export const SEARCH_SORT_OPTIONS = [RELEVANCE, RECENCY, HIGH_TO_LOW, LOW_TO_HIGH];

export const NEW_SORT_OPTIONS = [FEATURED, RECENCY_NEW, HIGH_TO_LOW, LOW_TO_HIGH];

export const SALE_SORT_OPTIONS = [FEATURED_SALE, RECENCY, HIGH_TO_LOW, LOW_TO_HIGH];

export const FAVORITE_SORT_OPTIONS = [RECENTLY_ADDED, HIGH_TO_LOW, LOW_TO_HIGH];

export const TRUNKSHOW_SORT_OPTIONS = [
  { name: 'Featured', value: 'default' },
  { name: 'Just In', value: 'active_start' },
  { name: 'Ending Soon', value: 'active_end' },
  { name: 'Designer A-Z', value: 'name' }
];
