import { useMemo } from 'react';
import { useUrlParams } from './useUrlParams';

const getCategoryPath = (
  vertical: string | string[],
  category: string | string[],
  subcategory: string | string[]
) =>
  [vertical, category, subcategory]
    .map(part => (Array.isArray(part) ? part[0] : part))
    .filter(Boolean)
    .join('/');

export const useCategoryPath = () => {
  const {
    params: { vertical, category, subcategory }
  } = useUrlParams();

  return useMemo(
    () => getCategoryPath(vertical, category, subcategory),
    [category, subcategory, vertical]
  );
};
